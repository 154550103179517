var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { RootLayout } from '../root/root.component';
var ExecutiveLayout = /** @class */ (function (_super) {
    __extends(ExecutiveLayout, _super);
    function ExecutiveLayout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.menuItems = [
            {
                label: 'Sign ups',
                routerLink: '/signups',
                iconType: 'pg',
                iconName: 'home'
            },
        ];
        return _this;
    }
    ExecutiveLayout.prototype.ngOnInit = function () {
        pg.isHorizontalLayout = true;
        this.changeLayout('horizontal-menu');
        this.changeLayout('horizontal-app-menu');
    };
    return ExecutiveLayout;
}(RootLayout));
export { ExecutiveLayout };
