import { OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { ListViewContainerComponent } from '../list-view-container/list-view-container.component';
var ListItemComponent = /** @class */ (function () {
    function ListItemComponent(pgItemView) {
        this.pgItemView = pgItemView;
    }
    Object.defineProperty(ListItemComponent.prototype, "content", {
        get: function () {
            return this._content;
        },
        enumerable: true,
        configurable: true
    });
    ListItemComponent.prototype.ngOnInit = function () {
        this.pgItemView._items.push(this);
    };
    ListItemComponent.prototype.ngOnDestroy = function () {
        this.pgItemView._items.splice(this.pgItemView._items.indexOf(this), 1);
    };
    return ListItemComponent;
}());
export { ListItemComponent };
