import { OnDestroy, ElementRef } from '@angular/core';
import { pagesToggleService } from '../../services/toggler.service';
var SearchOverlayComponent = /** @class */ (function () {
    function SearchOverlayComponent(el, toggler) {
        var _this = this;
        this.el = el;
        this.toggler = toggler;
        this._isEnabled = false;
        this.isVisible = false;
        this.value = '';
        this.toggleSubscription = this.toggler.searchToggle.subscribe(function (toggleValue) {
            _this.open();
        });
    }
    SearchOverlayComponent.prototype.ngOnDestroy = function () {
        this.toggleSubscription.unsubscribe();
    };
    Object.defineProperty(SearchOverlayComponent.prototype, "isEnabled", {
        get: function () {
            return this._isEnabled;
        },
        set: function (isEnabled) {
            this.isEnabled = isEnabled;
        },
        enumerable: true,
        configurable: true
    });
    SearchOverlayComponent.prototype.close = function ($event) {
        $event.preventDefault();
        this.isVisible = false;
        this.value = '';
    };
    SearchOverlayComponent.prototype.open = function () {
        var _this = this;
        this.isVisible = true;
        this.value = '';
        setTimeout(function () {
            _this.searchField.nativeElement.focus();
        }, 200);
    };
    SearchOverlayComponent.prototype.onKeydownHandler = function (e) {
        var nodeName = e.target.nodeName;
        // Ignore When focus on input, textarea & contenteditable
        if (nodeName === 'INPUT' || nodeName === 'TEXTAREA' || e.target.contentEditable === 'true') {
            return;
        }
        // Ignore Escape
        if (this.isVisible && e.keyCode === 27) {
            this.isVisible = false;
            this.value = '';
        }
        // Ignore Keyes
        if (e.which !== 0 && e.charCode !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey && e.keyCode !== 27) {
            this.isVisible = true;
            if (!this.value) {
                this.value = String.fromCharCode(e.keyCode | e.charCode);
            }
            this.searchField.nativeElement.focus();
        }
    };
    SearchOverlayComponent.prototype.searchKeyPress = function (event) {
        if (this.isVisible && event.keyCode === 27) {
            this.isVisible = false;
            this.value = '';
        }
    };
    SearchOverlayComponent.prototype.fadeInComplete = function () {
    };
    return SearchOverlayComponent;
}());
export { SearchOverlayComponent };
