import { OnInit, ElementRef, Renderer2 } from '@angular/core';
var pgRetinaDirective = /** @class */ (function () {
    function pgRetinaDirective(El, renderer) {
        this.El = El;
        this.renderer = renderer;
        this.isRetina = false;
        this.isRetina = window.devicePixelRatio > 1;
    }
    Object.defineProperty(pgRetinaDirective.prototype, "src2x", {
        set: function (value) {
            this._srcRetina = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(pgRetinaDirective.prototype, "src1x", {
        set: function (value) {
            this._src = value;
        },
        enumerable: true,
        configurable: true
    });
    pgRetinaDirective.prototype.ngOnInit = function () {
        if (this.isRetina) {
            this.renderer.setAttribute(this.El.nativeElement, 'src', this._srcRetina);
        }
    };
    return pgRetinaDirective;
}());
export { pgRetinaDirective };
