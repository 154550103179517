import { Routes } from '@angular/router';
//Layouts
import {
  ExecutiveLayout,
} from './@pages/layouts';


export const AppRoutes: Routes = [

  {
    path: '',
    data: {
        breadcrumb: 'Home'
    },
    component: ExecutiveLayout,
    children: [
      {
        path: '',
        redirectTo: 'signups',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: './@pages/pages/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'signups',
        loadChildren: './@pages/pages/signups/signups.module#SignupsModule',
      },
    ]
  }
];
