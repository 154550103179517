/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-view-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/observers";
import * as i4 from "ngx-perfect-scrollbar";
import * as i5 from "./list-view-container.component";
var styles_ListViewContainerComponent = [i0.styles];
var RenderType_ListViewContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListViewContainerComponent, data: {} });
export { RenderType_ListViewContainerComponent as RenderType_ListViewContainerComponent };
function View_ListViewContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ListViewContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ListViewContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ListViewContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "list-view-group-container"]], [[2, "list-view-animated", null], [2, "list-view-animated", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "list-view-group-header text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListViewContainerComponent_3)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListViewContainerComponent_4)), i1.ɵdid(6, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit._itemHeading; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit._content; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.elems[_v.context.index] == null) ? null : _co.elems[_v.context.index].animated); var currVal_1 = _v.context.$implicit.animated; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ListViewContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { itemListWrapper: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "list-view boreded no-top-border scrollable full-height relative"]], null, [[null, "cdkObserveContent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkObserveContent" === en)) {
        var pd_0 = (_co.cacheElements() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 1196032, null, 0, i3.CdkObserveContent, [i3.ContentObserver, i1.ElementRef, i1.NgZone], null, { event: "cdkObserveContent" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "list-view-fake-header"]], [[2, "list-view-hidden", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListViewContainerComponent_1)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["itemListWrapper", 1]], null, 3, "div", [["class", "scroll-wrapper list-view-wrapper"]], null, [[null, "scroll"], [null, "ps-scroll-y"], [null, "ps-scroll-x"], [null, "ps-scroll-up"], [null, "ps-scroll-down"], [null, "ps-scroll-left"], [null, "ps-scroll-right"], [null, "ps-y-reach-end"], [null, "ps-y-reach-start"], [null, "ps-x-reach-end"], [null, "ps-x-reach-start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ps-scroll-y" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).psScrollY($event) !== false);
        ad = (pd_0 && ad);
    } if (("ps-scroll-x" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).psScrollX($event) !== false);
        ad = (pd_1 && ad);
    } if (("ps-scroll-up" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).psScrollUp($event) !== false);
        ad = (pd_2 && ad);
    } if (("ps-scroll-down" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).psScrollDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("ps-scroll-left" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).psScrollLeft($event) !== false);
        ad = (pd_4 && ad);
    } if (("ps-scroll-right" === en)) {
        var pd_5 = (i1.ɵnov(_v, 7).psScrollRight($event) !== false);
        ad = (pd_5 && ad);
    } if (("ps-y-reach-end" === en)) {
        var pd_6 = (i1.ɵnov(_v, 7).psReachEndY($event) !== false);
        ad = (pd_6 && ad);
    } if (("ps-y-reach-start" === en)) {
        var pd_7 = (i1.ɵnov(_v, 7).psReachStartY($event) !== false);
        ad = (pd_7 && ad);
    } if (("ps-x-reach-end" === en)) {
        var pd_8 = (i1.ɵnov(_v, 7).psReachEndX($event) !== false);
        ad = (pd_8 && ad);
    } if (("ps-x-reach-start" === en)) {
        var pd_9 = (i1.ɵnov(_v, 7).psReachStartX($event) !== false);
        ad = (pd_9 && ad);
    } if (("scroll" === en)) {
        var pd_10 = (_co.computeHeader() !== false);
        ad = (pd_10 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 999424, null, 0, i4.PerfectScrollbarDirective, [i1.NgZone, i1.KeyValueDiffers, i1.ElementRef, i1.PLATFORM_ID, [2, i4.PERFECT_SCROLLBAR_CONFIG]], { disabled: [0, "disabled"], config: [1, "config"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListViewContainerComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.topHeader; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isPerfectScrollbarDisabled; var currVal_3 = _co.config; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co._items; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fakeHeaderHidden; _ck(_v, 3, 0, currVal_0); }); }
export function View_ListViewContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pg-list-view-container", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ListViewContainerComponent_0, RenderType_ListViewContainerComponent)), i1.ɵdid(1, 4308992, null, 0, i5.ListViewContainerComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListViewContainerComponentNgFactory = i1.ɵccf("pg-list-view-container", i5.ListViewContainerComponent, View_ListViewContainerComponent_Host_0, {}, {}, []);
export { ListViewContainerComponentNgFactory as ListViewContainerComponentNgFactory };
