//Layouts
import { ExecutiveLayout, } from './@pages/layouts';
var ɵ0 = {
    breadcrumb: 'Home'
};
export var AppRoutes = [
    {
        path: '',
        data: ɵ0,
        component: ExecutiveLayout,
        children: [
            {
                path: '',
                redirectTo: 'signups',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                loadChildren: './@pages/pages/dashboard/dashboard.module#DashboardModule',
            },
            {
                path: 'signups',
                loadChildren: './@pages/pages/signups/signups.module#SignupsModule',
            },
        ]
    }
];
export { ɵ0 };
